import { HiOutlineEmojiSad } from "react-icons/hi";
import { css } from "@emotion/core";
import PulseLoader from "react-spinners/PulseLoader";

import loaderStyles from "../../styles/ui/Loader.module.css";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
`;

export default function Loader({ loading, title, msg, icon }) {
  return (
    <div className={loaderStyles.loaderWrapper}>
      <PulseLoader
        color={"#183b72"}
        loading={loading}
        css={override}
        size={18}
      />
      {title && (
        <h2 className="flex flex-col lg:flex-row items-center">
          {" "}
          {icon == "bigfile" && (
            <HiOutlineEmojiSad size="32" className="mr-2" />
          )}{" "}
          {title}
        </h2>
      )}
      <p>{msg}</p>
    </div>
  );
}
