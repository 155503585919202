import cookie from "cookie";

export function parseCookies(req) {
  return cookie.parse(req ? req.headers.cookie || "" : document.cookie);
}

export function getUserParams() {
  const { fdCategoria, fdProcesso } = parseCookies();
  return { ct: fdCategoria || 0, ps: fdProcesso || 0 };
}
