import { CookiesProvider } from "react-cookie";

import "../styles/globals.css";
import "../styles/styles-table.css";

import { userData } from "@/global/state-object";

import { createGlobalStyle, ThemeProvider } from "styled-components";

import { StateMachineProvider, createStore } from "little-state-machine";

import { AuthProvider } from "../contexts/AuthContext";

createStore({
  userData,
});

const GlobalStyles = createGlobalStyle`
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .main {
    padding: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 85%;
    min-width: 85%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .page-header {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
  }
  
  .rdt_Table {
    height: 100%;
  }

  .rdt_TableCol, .rdt_TableCell {
    font-size: 16px;
  }
  
  .data-table-extensions-filter {
    width: 100%;
    max-width: 400px;
    min-height: 75px;
  }

  @media (max-width: 768px) {
    .main {
      width: 95%;
    }
  }
  
  @media (max-width: 600px) {
    .grid {
      width: 100%;
      flex-direction: column;
    }
  }  
`;

function MyApp({ Component, pageProps }) {
  return (
    <>
      <GlobalStyles />
      <StateMachineProvider>
        <CookiesProvider>
          <AuthProvider>
            <Component {...pageProps} />
          </AuthProvider>
        </CookiesProvider>
      </StateMachineProvider>
    </>
  );
}

export default MyApp;
