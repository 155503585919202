export const userData = {
  codusuariops: "-1",
  codpessoa: "-1",
  nome: "",
  nomecfo: "",
  cpf: "",
  cpfcfo: "",
  datanascimento: "",
  datanascimentocfo: "",
  telefone: "",
  email: "",
  emailcfo: "",
  sexo: "",
  codmunicipio: "",
  codmunicipionaturalidade: "",
  codetd: "",
  hash: "hash",
  idps: "",
  nomeps: "",
  idareainteresse: "",
  agendamento: "",
  id360tipoatendimento: "",
  formaingresso: "",
  naturalidade: "",
  aceitelgpd: "",
  aceitemkt: "",
  codplanopgto: "",
  camposopcionais: [],
  rua: "",
  numero: "",
  complemento: "",
  bairro: "",
  cep: "",
  cidade: "",
  estado: "",
};
