import { createContext, useEffect, useState } from "react";
import {
  autenticaUsuario,
  validaUsuario,
  verificaInscricoes,
} from "../api/functions";
import Cookie from "js-cookie";
import { useStateMachine } from "little-state-machine";
import updateActionUserData from "../actions/actions";
import { useRouter } from "next/router";
import { userData } from "@/global/state-object";

import { parseCookies } from "../helpers/index";
import Loader from "@/components/ui/Loader";
import { set } from "react-hook-form";

export const AuthContext = createContext({});

export function AuthProvider({ children }) {
  const router = useRouter();
  const [userLogged, setUserLogged] = useState(false);

  const { actions, state } = useStateMachine({ updateActionUserData });
  const [msgErroAutentica, setMsgErroAutentica] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [stopLoadingToken, setStopLoadingToken] = useState(
    Cookie.get("fdStopLoading")
  );

  async function signIn(data) {
    const resAutentica = await autenticaUsuario(data);

    const { token, autenticacaoexterna, url } = resAutentica.data;

    if (resAutentica.status == 200) {
      const usuario =
        data.cpf.replaceAll(".", "").replace("-", "") || data.email;
      const resValida = await validaUsuario(usuario);

      if (resValida.status == 200) {
        const newUserData = {
          codusuariops: resValida.data.data[0].codusuariops,
          codpessoa: resValida.data.data[0].codpessoa,
          nome: resValida.data.data[0].nome,
          cpf: resValida.data.data[0].cpf,
          sexo: resValida.data.data[0].sexo,
          naturalidade: resValida.data.data[0].naturalidade,
          codetd: resValida.data.data[0].codetd,
          datanascimento: resValida.data.data[0].datanascimento,
          telefone: resValida.data.data[0].telefone,
          email: resValida.data.data[0].email,
          codmunicipionaturalidade:
            resValida.data.data[0].codmunicipionaturalidade,
          aceitelgpd: resValida.data.data[0].aceitelgpd,
          aceitemkt: resValida.data.data[0].aceitemkt,

          //campos endereco
          bairro: resValida.data.data[0].bairro,
          cep: resValida.data.data[0].cep,
          estado: resValida.data.data[0].estado,
          rua: resValida.data.data[0].rua,
          numero: resValida.data.data[0].numero,
          complemento: resValida.data.data[0].complemento,
          cidade: resValida.data.data[0].cidade,

          hash: "HASH",
          idps: "",
          idareainteresse: "",
          agendamento: "",
          codplanopgto: "",
          camposopcionais: [],
        };

        actions.updateActionUserData(newUserData);

        var inSixtyMinutes = new Date(new Date().getTime() + 60 * 60 * 1000);
        Cookie.set("fdToken", token, { expires: inSixtyMinutes });
        Cookie.set("fdUser", usuario, { expires: inSixtyMinutes });
        Cookie.set("fdAuthExt", autenticacaoexterna, {
          expires: inSixtyMinutes,
        });

        setUserLogged(true);

        // router.push("/inscricoes");

        const resVerificaInscricoes = verificaInscricoes(
          resValida.data.data[0].codusuariops
        );

        resVerificaInscricoes
          .then(function (res) {
            if (res.data.length == 0) {
              router.push("/inscricoes", { shallow: true });
            } else {
              router.push("/minhas-inscricoes", { shallow: true });
            }
          })
          .catch(function (error) {
            Sentry.captureException(error);
          });
      }
    }

    return resAutentica;
  }

  async function signOut() {
    actions.updateActionUserData(userData);

    setUserLogged(false);

    Cookie.remove("fdToken");
    Cookie.remove("fdUser");
    Cookie.remove("fdAuthExt");
    Cookie.remove("fdProcesso");
    Cookie.remove("fdCategoria");

    router.push("/login");
  }

  useEffect(() => {
    const userToken = Cookie.get("fdToken");
    if (userToken && userToken != "") {
      setUserLogged(true);
    }
  }, []);

  useEffect(() => {
    setIsLoading(false);

    if (router.query.ct && router.query.ps) {
      var inSixtyMinutes = new Date(new Date().getTime() + 60 * 60 * 1000);
      Cookie.set("fdCategoria", router.query.ct, { expires: inSixtyMinutes });
      Cookie.set("fdProcesso", router.query.ps, { expires: inSixtyMinutes });
    }
  }, [router.query]);

  useEffect(() => {
    if (!stopLoadingToken && stopLoadingToken != "") {
      setIsLoading(false);
    }
  }, [stopLoadingToken]);

  useEffect(() => {
    //trocar para pathname no lugar de asPath
    const cookies = parseCookies();
    let redirectCompleted = false;
    // if (userLogged && !redirectCompleted && router.asPath == "/login") {
    //   router.push("/minhas-inscricoes");
    // }

    if (
      !redirectCompleted &&
      cookies.fdToken == undefined &&
      router.pathname != "/cadastro" &&
      router.pathname != "/login" &&
      router.pathname != "/"
    ) {
      signOut();
      router.push("/login");
    }

    // if (userLogged && router.asPath == "/") {
    //   router.push("/login");
    // }

    // if (
    //   !userLogged &&
    //   !redirectCompleted &&
    //   router.asPath != "/login" &&
    //   router.asPath != "/cadastro"
    // ) {
    //   signOut();
    // }

    return function cleanup() {
      redirectCompleted = true;
    };
  }, [userLogged]);

  function Loading() {
    return (
      <>
        {isLoading && (
          <Loader
            loading={isLoading}
            msg="Aguarde enquanto validamos seus dados"
          />
        )}
      </>
    );
  }

  return (
    <AuthContext.Provider
      value={{ userLogged, signIn, signOut, Loading, setIsLoading }}
    >
      {children}
    </AuthContext.Provider>
  );
}
