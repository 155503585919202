import axios from "axios";
import * as Sentry from "@sentry/nextjs";
import Cookie from "js-cookie";

export async function autenticaUsuario(data) {
  const urlAutentica = "https://api.fadisma.com.br/autentica";

  const usuario = data.cpf.replaceAll(".", "").replace("-", "") || data.email;

  const resAutentica = axios
    .post(urlAutentica, {
      codusuario: usuario,
      senha: data.senha || data.datanascimento.split("-").reverse().join(""),
    })
    .then(function (res) {
      return res;
    })
    .catch(function (error) {
      if (error.response) {
        Cookie.set("fdStopLoading", true);
        alert(error.response.data.msg);
      }
      Sentry.captureException(error);
      return error;
    });

  return resAutentica;
}

export async function enviaDadosConsultaUsuario(userConsulta) {}

export function validaUsuario(usuario) {
  const userHash = "hash";
  const options = {
    method: "POST",
    url: "https://cron.fadisma.com.br/inscricoes/validausuario/" + userHash,
    headers: { "Content-Type": "application/json" },
    data: { chave: usuario },
  };

  const resValida = axios
    .request(options)
    .then(function (res) {
      return res;
    })
    .catch(function (error) {
      Sentry.captureException(error);
    });

  return resValida;
}

export async function cadastraUsuario(data) {
  const options = {
    method: "POST",
    url: "https://cron.fadisma.com.br/inscricoes/cadastrausuario/hash",
    headers: { "Content-Type": "application/json" },
    data: data,
    timeout: 60000,
  };

  const resCadastro = axios
    .request(options)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      if (error.response) {
        alert(
          "Identificamos uma instabilidade no sistema, tente novamente em alguns instantes."
        );
      }
      Sentry.captureException(error);
    });

  return resCadastro;
}

export function getCursoCategoriasIngresso(chaveCurso) {
  const options = {
    method: "GET",
    url:
      "https://cron.fadisma.com.br/inscricoes/listaopcoesprocessos/" +
      chaveCurso +
      "/hash",
    headers: { "Content-Type": "application/json" },
  };

  const resCategorias = axios
    .request(options)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      Sentry.captureException(error);
    });

  return resCategorias;
}

export function getAgendamentos(chaveCurso) {
  const options = {
    method: "GET",
    url:
      "https://cron.fadisma.com.br/inscricoes/listaopcoesagendamentos/" +
      chaveCurso +
      "/hash",
    headers: { "Content-Type": "application/json" },
  };

  const resCategorias = axios
    .request(options)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      Sentry.captureException(error);
    });

  return resCategorias;
}

export function cadastraInscricao(dadosInscricao) {
  const options = {
    method: "POST",
    url: "https://cron.fadisma.com.br/inscricoes/cadastrainscricao/hash",
    headers: { "Content-Type": "application/json" },
    data: dadosInscricao,
  };

  const resCadastro = axios
    .request(options)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      if (error.response) {
        alert(error.response.data.msg);
      }
      Sentry.captureException(error);
      return error;
    });

  return resCadastro;
}

export async function verificaInscricoes(codusuariops) {
  const dadosUsuario = {
    codusuariops: codusuariops,
  };

  const options = {
    method: "POST",
    url: "https://cron.fadisma.com.br/inscricoes/listainscricoes/hash",
    headers: { "Content-Type": "application/json" },
    data: dadosUsuario,
  };

  const resInscricoes = axios
    .request(options)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      Sentry.captureException(error);
    });

  return resInscricoes;
}

export async function listaProcessosAbertos() {
  const options = {
    method: "GET",
    url: "https://cron.fadisma.com.br/inscricoes/listaprocessosemaberto",
  };

  const processosAbertos = axios
    .request(options)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      Sentry.captureException(error);
    });

  return processosAbertos;
}

export async function listaProcessosAbertosPorCategoria(ct) {
  const options = {
    method: "GET",
    url:
      "https://cron.fadisma.com.br/inscricoes/listaprocessosemabertoporcategoria/" +
      ct,
  };

  const processosAbertos = axios
    .request(options)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      Sentry.captureException(error);
    });

  return processosAbertos;
}

export async function listaProcessosAbertosPorCategoriaEProcesso(ct, ps) {
  const options = {
    method: "GET",
    url:
      "https://cron.fadisma.com.br/inscricoes/listaprocessosemabertoporcategoriaps/" +
      ct +
      "/" +
      ps,
  };

  const processosAbertos = axios
    .request(options)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      Sentry.captureException(error);
    });

  return processosAbertos;
}

export function listaDocumentosOpcao(idps, idareainteresse, codusuariops) {
  const options = {
    method: "GET",
    url:
      "https://cron.fadisma.com.br/inscricoes/listadocumentosopcao/" +
      idps +
      "/" +
      idareainteresse +
      "/" +
      codusuariops,
  };

  const resListaDocumentos = axios
    .request(options)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      Sentry.captureException(error);
    });

  return resListaDocumentos;
}

export async function enviaDocumentoInscricao(dadosDoc) {
  const options = {
    method: "POST",
    url: "https://cron.fadisma.com.br/inscricoes/uploaddocumento",
    headers: {
      "Content-Type":
        "multipart/form-data; boundary=---011000010111000001101001",
    },
    data: dadosDoc,
  };
  const resEnvia = axios
    .request(options)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      Sentry.captureException(error);
    });

  return resEnvia;
}

export async function getStatusInscricao(id360) {
  const options = {
    method: "GET",
    url: "https://cron.fadisma.com.br/inscricoes/statusinscricao/" + id360,
  };

  const resStatus = axios
    .request(options)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      Sentry.captureException(error);
    });

  return resStatus;
}

export async function getEstados() {
  const options = {
    method: "GET",
    url: "https://cron.fadisma.com.br/global/estados",
  };

  const resEstados = axios
    .request(options)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      Sentry.captureException(error);
    });

  return resEstados;
}

export async function getCidadesEstado(codetd) {
  const options = {
    method: "GET",
    url: "https://cron.fadisma.com.br/global/cidades/" + codetd,
  };

  const resEstados = axios
    .request(options)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      Sentry.captureException(error);
    });

  return resEstados;
}

export async function validaCPF(cpf) {
  const options = {
    method: "GET",
    url: "https://api.cpfcnpj.com.br/9b0f300a18a654d889ddde4ac4b9ee3d/7/" + cpf,
  };

  //dados homolog
  // const options = {
  //   method: "GET",
  //   url: "https://api.cpfcnpj.com.br/5ae973d7a997af13f0aaf2bf60e65803/7/" + cpf,
  // };

  const resCPF = axios
    .request(options)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      Sentry.captureException(error);
    });

  return resCPF;
}

export async function getPlanosPagamento(idps, idareainteresse) {
  const options = {
    method: "GET",
    url:
      "https://cron.fadisma.com.br/inscricoes/listaplanosdepagamento/" +
      idps +
      "/" +
      idareainteresse,
  };

  const resPlanos = axios
    .request(options)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      Sentry.captureException(error);
    });

  return resPlanos;
}

export function getCamposAdicionais(idps) {
  const options = {
    method: "GET",
    url: "https://cron.fadisma.com.br/inscricoes/listacamposopcionais/" + idps,
  };

  const res = axios
    .request(options)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      Sentry.captureException(error);
    });

  return res;
}

export function getTermoAceite(idps, idareainteresse) {
  const userHash = "hash";
  const options = {
    method: "GET",
    url:
      "https://cron.fadisma.com.br/inscricoes/termoaceite/" +
      idps +
      "/" +
      idareainteresse +
      "/" +
      userHash,
  };

  const resTermo = axios
    .request(options)
    .then(function (res) {
      return res;
    })
    .catch(function (error) {
      Sentry.captureException(error);
    });

  return resTermo;
}

export async function validaCEP(cep) {
  try {
    const { data } = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);

    return data;
  } catch {
    return false;
  }
}

export const apiActiveCampaign = axios.create({
  method: "POST",
  baseURL: "https://fadisma83734.api-us1.com/api/3",
  headers: {
    accept: "application/json",
    "Api-Token":
      "05525b1102f8d2a1815e1574e95bb34b0f52b78136890889c1f43bb7895f89eead5e4e58",
  },
});

export async function criaContatoActiveCampaign(contact) {
  await axios.post(`/api/activeCampaignContact`, {
    contact,
  });
}
